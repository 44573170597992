import {ChakraProvider, Skeleton} from '@chakra-ui/react';
import {HaliRenderProvider, HaligatorProvider} from '@synako/haligator';
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import theme from './chakra/index.ts';
import {createClient} from './halitreck-factory.js';
import {i18nConfig} from './locales/i18n-config.js';
import {toastTheme} from './chakra/components/Toast/index.ts';
import * as locales from 'date-fns/locale';
import {getLocale} from './utils/get-locale.ts';
import {setDefaultOptions as defaultDateFnsOptions} from 'date-fns';
import {middlewares} from '@synako/halitrek';

const locale = getLocale(i18next.language);

const halitreck = createClient(locale);
halitreck.fetcher.use(middlewares.clientApp('client'));

defaultDateFnsOptions({locale: locales[locale as keyof typeof locales]});
i18nConfig(locale);
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HaligatorProvider client={halitreck}>
      <HaliRenderProvider defaults={{
        skeleton:()=><Skeleton w='100%' h='10' m='1'/>,
      }} >
        <ChakraProvider theme={theme} toastOptions={toastTheme}>
          <App />
        </ChakraProvider>
      </HaliRenderProvider>
    </HaligatorProvider>
  </React.StrictMode>
);
