import React from 'react';
import {Box, VStack, Text, Link, Flex} from '@chakra-ui/react';
import {ExternalLinkIcon} from '@chakra-ui/icons';
import index from '@/routes/ffconnect';
import {fromRoute} from '@synako/enhanced-router';
import {useTranslation} from 'react-i18next';
import {BlueButton} from '@/components/Button/BlueButton';

// eslint-disable-next-line react-refresh/only-export-components
export const connectionRoute = fromRoute(index, {
  path: 'connection'
});

export const Connection: React.FC = () => {
  const {t} = useTranslation('common');

  return (
    <>
      <Text as="h1" fontSize='3xl' fontWeight='700' textAlign="center" width="100%"
        mb={{base: '3xl', md: '5xl'}}>
        {t('authentification.bienvenu')} !
      </Text>

      <VStack spacing={8} align="stretch" width="100%" px='5'>
        <Box borderWidth={1} borderRadius="8px" p={6} textAlign="center">
          <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="600" color="blue.main" mb={4}>{t('authentification.compte_france_frais')}</Text>
          <BlueButton
            onClick={() => {window.location.href = '/authn/auth0/login?prompt=login';}}
            texte={`${t('authentification.connectez_vous')} →`}/>
        </Box>

        <Box borderWidth={1} borderRadius="8px" p={6} textAlign="center">
          <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="600" color="blue.main" mb={2}>{t('authentification.premiere_connexion')}</Text>
          <Text fontSize={{base: 'md', md: 'sm'}} color="gray.dark" mb={4} px='8'>
            {t('authentification.utiliser_email')}
          </Text>
          <BlueButton
            onClick={() => {window.location.href = '/authn/auth0/login?prompt=login&screen_hint=signup';}}
            texte={t('authentification.activer_compte')}/>
        </Box>

        <Flex align="center" borderWidth={1} borderRadius="8px" p={5} mt={{base: '30px', md: '50px'}}>
          <Box boxSize="50px" mr={4} bgImage="url('/ffconnect/connexion/img/caller.png')" bgSize="cover" bgPosition="center" />
          <Box fontSize={{base: 'lg', md: 'md'}}>
            <Text fontWeight="700" color="blue.main" mb={1}>{t('authentification.devenir_client')}</Text>
            <Link href="https://www.francefrais.fr/contact" isExternal color="blue.main" textDecoration="underline">
              {t('authentification.contactez_nous')} <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        </Flex>
      </VStack>
    </>
  );
};
