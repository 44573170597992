import {Footer} from '@/domains/orga/Footer';
import {Navbar} from '@/components/Navbar/Navbar';
import {BottomNav} from '@/components/Navbar/BottomNav/BottomNav';
import {Box, Portal, VStack} from '@chakra-ui/react';
import {Outlet} from 'react-router-dom';

export const Layout: React.FC = () => {
  return (
    <>
      <VStack gap='0' minH='100vh' position='relative'>
        <Navbar/>
        <Box position='relative' w="100vw" flexGrow='1'>
          <Outlet/>
        </Box>
        <Footer />
      </VStack>
      <Portal>
        <BottomNav />
      </Portal>
    </>
  );
};
